
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { EstimationClient } from '@/services/Services';
import utils from '@/utils';

@Options({})
export default class editLayerProductionProcessModal extends Vue {

    @Prop() estimationIdentifier: string;
    @Prop() layer: OM.LayerComposition;
    @Prop() allProductionProcesses: OM.ProductionProcessListVM[];
    @Prop() currentProductionProcesses: OM.FabricProductionProcess[];
    @Prop() countries: OM.TextIdentifier[];
    @Prop() productionPhase: string;
    @Prop() saveCallback: (layer: OM.AddEstimationLayerProductionProcessVM) => void;
    @Prop() productionProcessName: string;
    @Prop() rawMaterialCountryAndUnits: { [key: string]: OM.KeyValuePairOfGuidAndInteger }

    model = new OM.AddEstimationLayerProductionProcessVM();
    originCountryReadonly: boolean = false;

    created() {
        this.model.productionProcessIdentifier = this.allProductionProcesses.find(x => x.name == this.productionProcessName).identifier;
        this.model.identifier = this.estimationIdentifier;
        this.model.layerName = this.layer.layerName;
        this.model.rawMaterialCountryAndUnits = utils.JsonClone(this.rawMaterialCountryAndUnits);

        // this.countries.unshift({
        //     text: this.$localizationService.getLocalizedValue("Generic", "Generic"),
        //     identifier: ""
        // });
        
        this.model.identifier = this.estimationIdentifier;
        this.model.layerName = this.layer.layerName;

        (<any>window).sam = this.currentProductionProcesses;
    }

    get disabled(){
        return !this.model.productionProcessIdentifier;
    }

    get filteredRawMaterials(){
        return this.layer.fabricCompositions.map( x => x.rawMaterial);
    }

    addOrRemoveRawMaterial(identifier: string){
        var add = Object.keys(this.model.rawMaterialCountryAndUnits).indexOf(identifier) == -1;

        if(!add){
            this.model.processesToRemove.push({
                identifier: identifier,
                text: this.productionProcessName
            })
            delete this.model.rawMaterialCountryAndUnits[identifier];
            return;
        } else {
            this.model.processesToRemove = this.model.processesToRemove.filter(x => !(x.identifier == identifier))
        }

        EstimationClient.getCountryOfDestinationFromPreviousStep(this.estimationIdentifier, identifier, this.productionPhase, this.layer.layerName)
        .then( x => {
            var country = this.countries.find(y => y.text == x).identifier;
            this.model.rawMaterialCountryAndUnits[identifier] = {
                key: country,
                value: 0
            }
        })
    }

    save() {
        this.saveCallback(this.model);
    }
}
